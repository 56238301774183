import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Layout from '../components/layout';
import SEO from '../components/seo';

// FIXME: Add typing for Gatsby GrapthQL queries
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NotFoundPage: React.FC<any> = ({ data }): ReactElement => {
  const { t } = useTranslation();
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <SEO title={t('NOT_FOUND_TITLE')} />
      <div className="section-page-tags section-page-general wrap">
        <h1>{t('NOT_FOUND_TITLE')}</h1>
        <p>{t('NOT_FOUND_DESCRIPTION')}</p>
        <img src="/cooking.gif" alt="Si Chef. cooking error" className="error-loading"></img>
        <div className="page-tags-wrap flex">
          <AniLink fade duration={1.2} to={'/recetas'} className="item-wrap flex post tag-people tag-journey no-image">
            <article>
              <h2>{t('NOT_FOUND_RECIPES_TITLE')}</h2>
              <div className="item-meta white is-primary-tag ">
                <span>{t('NOT_FOUND_RECIPES_DESCRIPTION')}</span>
              </div>
            </article>
          </AniLink>
          <AniLink
            fade
            duration={1.2}
            to={'/chefs'}
            className="item-wrap flex post tag-story tag-hash-salmon tag-hash-post-salmon no-image white"
          >
            <article>
              <h2 className="white">{t('NOT_FOUND_CHEFS_TITLE')}</h2>
              <div className="item-meta white is-primary-tag white">
                <span>{t('NOT_FOUND_CHEFS_DESCRIPTION')}</span>
              </div>
            </article>
          </AniLink>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
